<template>
  <Dialog :visible="showEdit" modal class="edit-dialog">
    <template #header>
      <div class="flex justify-between w-full">
        <span class="p-dialog-title">{{ dictionary.edit_header }}</span>
        <Button icon="pi pi-times" @click="handleCloseEdit" />
      </div>
    </template>
    <div
      v-if="loading"
      class="spinner-wrapper w-full h-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50"
    >
      <ProgressSpinner
        class="spinner flex items-center justify-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
        strokeWidth="2"
        animationDuration="3s"
      />
    </div>
    <form
      @submit.prevent="!selectedModule ? onSubmit($event) : onUpdate($event)"
      class="w-full custom:text-xs xl:text-base"
    >
      <div class="flex flex-row w-full">
        <div v-if="imgUrl && isValidUrl" class="w-1/5 mr-2">
          <img :src="imgUrl" alt="training_icon" class="preview-img" />
        </div>
        <div v-else class="img-placeholder mr-2">
          <Skeleton width="100%" height="100%" />
        </div>
        <div class="w-4/5">
          <small class="flex items-center flex-row"
            >{{ dictionary.edit_sharepoint }}
            <Button
              as="a"
              label="SharePoint Library"
              href="https://schwarzit.sharepoint.com/sites/global-lidl-leon/SiteAssets/Forms/AllItems.aspx?id=%2Fsites%2Fglobal%2Dlidl%2Dleon%2FSiteAssets%2FMapper"
              target="_blank"
              rel="noopener"
              class="m-0 pl-2 py-0"
          /></small>
          <FloatLabel>
            <InputText id="img_url" v-model="imgUrl" />
            <label for="img_url">{{ dictionary.edit_url }}</label>
          </FloatLabel>
          <small
            v-if="(isSubmitted && !imgUrl) || !isValidUrl"
            class="p-error"
            >{{ dictionary.edit_url_error }}</small
          >
        </div>
      </div>

      <div class="grid grid-cols-4 gap-1">
        <FloatLabel class="col-span-4">
          <InputText id="title" v-model="title" />
          <label for="title">{{ dictionary.edit_title }}</label>
        </FloatLabel>
        <small v-if="isSubmitted && !title" class="p-error">{{
          dictionary.edit_error_field
        }}</small>
      </div>

      <div class="grid grid-cols-4 gap-4">
        <div class="col-span-2">
          <FloatLabel>
            <InputText id="objective" v-model="objective" />
            <label for="objective">{{ dictionary.edit_objective }}</label>
          </FloatLabel>
          <small v-if="isSubmitted && !objective" class="p-error">{{
            dictionary.edit_error_field
          }}</small>
        </div>
        <div class="col-span-1">
          <FloatLabel>
            <InputText id="short" v-model="shortname" class="uppercase" />
            <label for="short">{{ dictionary.edit_shortname }}</label>
          </FloatLabel>
          <small v-if="isSubmitted && !shortname" class="p-error">{{
            dictionary.edit_error_field
          }}</small>
        </div>
        <div class="col-span-1">
          <FloatLabel>
            <Dropdown
              v-model="selectedDepartment"
              inputId="dd-department"
              :options="departments"
              optionLabel="name"
              class="w-full"
            />
            <label for="dd-department">{{ dictionary.edit_department }}</label>
          </FloatLabel>
          <small v-if="isSubmitted && !selectedDepartment" class="p-error">{{
            dictionary.edit_error_field
          }}</small>
        </div>
      </div>

      <div class="grid grid-cols-4 gap-4">
        <div class="col-span-2">
          <FloatLabel>
            <Dropdown
              v-model="selectedDuration"
              inputId="dd-duration"
              :options="durations"
              optionLabel="name"
              class="w-full"
            />
            <label for="dd-duration"
              >{{ dictionary.edit_duration }} (min)</label
            >
          </FloatLabel>
          <small v-if="isSubmitted && !selectedDuration" class="p-error">{{
            dictionary.edit_error_field
          }}</small>
        </div>
        <div class="col-span-1">
          <FloatLabel>
            <Dropdown
              v-model="selectedType"
              inputId="dd-type"
              :options="types"
              optionLabel="name"
              class="w-full"
            />
            <label for="dd-type">{{ dictionary.edit_type }}</label>
          </FloatLabel>
          <small v-if="isSubmitted && !selectedType" class="p-error">{{
            dictionary.edit_error_field
          }}</small>
        </div>
        <div class="col-span-1">
          <FloatLabel>
            <Dropdown
              v-model="selectedElement"
              inputId="dd-elements"
              :options="trainingElements"
              optionLabel="name"
              class="w-full"
            />
            <label for="dd-elements">{{ dictionary.edit_elements }}</label>
          </FloatLabel>
          <small v-if="isSubmitted && !selectedElement" class="p-error">{{
            dictionary.edit_error_field
          }}</small>
        </div>
      </div>

      <div class="grid grid-cols-4 gap-4">
        <div class="col-span-2">
          <FloatLabel>
            <Dropdown
              v-model="selectedTarget"
              inputId="dd-target"
              :options="targets"
              optionLabel="name"
              class="w-full"
            />
            <label for="dd-target">{{ dictionary.edit_target }}</label>
          </FloatLabel>
          <small v-if="isSubmitted && !selectedTarget" class="p-error">{{
            dictionary.edit_error_field
          }}</small>
        </div>
        <div class="col-span-2">
          <FloatLabel>
            <Dropdown
              v-model="selectedGroup"
              inputId="dd-experience"
              :options="groups"
              optionLabel="name"
              class="w-full"
            />
            <label for="dd-experience">{{ dictionary.edit_group }}</label>
          </FloatLabel>
          <small v-if="isSubmitted && !selectedGroup" class="p-error">{{
            dictionary.edit_error_field
          }}</small>
        </div>
      </div>

      <div class="mt-4 mb-2 flex flex-row w-full items-center justify-between">
        <span class="text-left mr-2 custom:text-sm xl:text-base">{{
          dictionary.edit_create_topic
        }}</span>
        <Button
          @click="handleAddNewTopic()"
          icon="pi pi-plus-circle"
          :label="dictionary.edit_topic_btn"
          class="dataview-btn m-0 p-1"
          :disabled="topics.length >= 6"
        />
      </div>
      <div
        v-for="(topic, tIndex) in topics"
        :key="tIndex"
        class="flex flex-col border border-slate-600 px-2 pb-4 mb-4"
      >
        <div class="w-full flex justify-end mt-2">
          <Button
            @click="handleDeleteTopic(tIndex)"
            icon="pi pi-times"
            class="delete-btn m-0"
            :title="dictionary.edit_delete_topic"
            :disabled="tIndex < 1"
          />
        </div>
        <div class="w-full flex flex-row">
          <div class="flex flex-col w-1/2 ml-2 mr-4">
            <FloatLabel :class="['custom:text-sm xl:text-base']">
              <InputText :id="'tp_' + tIndex" v-model="topic.name" />
              <label :for="'tp_' + tIndex">{{ dictionary.edit_topic }}</label>
            </FloatLabel>
            <small v-if="isSubmitted && !topic.name" class="p-error">{{
              dictionary.edit_error_field
            }}</small>
          </div>
          <div class="flex flex-col w-1/2">
            <FloatLabel :class="['custom:text-sm xl:text-base']">
              <InputText :id="'kw_' + tIndex" v-model="topic.knowledge" />
              <label :for="'kw_' + tIndex">{{
                dictionary.edit_knowledge
              }}</label>
            </FloatLabel>
            <small v-if="isSubmitted && !topic.knowledge" class="p-error">{{
              dictionary.edit_error_field
            }}</small>
          </div>
        </div>
        <div class="flex flex-col">
          <div class="mt-4 flex flex-row w-full items-center justify-between">
            <span class="text-left mr-2 custom:text-sm xl:text-base">{{
              dictionary.edit_create_stopic
            }}</span>
            <Button
              @click="handleAddNewSubTopic(tIndex)"
              icon="pi pi-plus-circle"
              :label="dictionary.edit_stopic_btn"
              class="dataview-btn m-0 p-1"
              :disabled="topic.stopic.length >= 6"
            />
          </div>
          <ol class="w-full">
            <li
              v-for="(stopic, index) in topic.stopic"
              :key="index"
              class="flex flex-row items-center"
            >
              <span class="mt-4 mr-2 text-center">{{ index + 1 }}.</span>
              <div class="flex flex-col w-1/2 mr-4">
                <FloatLabel :class="['custom:text-sm xl:text-base']">
                  <InputText :id="'stp_' + index" v-model="stopic.name" />
                  <label :for="'stp_' + index">{{
                    dictionary.edit_stopic
                  }}</label>
                </FloatLabel>
                <small v-if="isSubmitted && !stopic.name" class="p-error">{{
                  dictionary.edit_error_field
                }}</small>
              </div>
              <div class="flex flex-col w-1/2">
                <FloatLabel :class="['custom:text-sm xl:text-base']">
                  <Dropdown
                    v-model="stopic.skill"
                    inputId="dd-skills"
                    :options="skills"
                    :optionLabel="(option) => option"
                    class="w-full"
                  />
                  <label for="dd-skills">{{ dictionary.edit_skill }}</label>
                </FloatLabel>
                <small v-if="isSubmitted && !stopic.skill" class="p-error">{{
                  dictionary.edit_error_field
                }}</small>
              </div>
              <div class="mt-5 ml-4">
                <Button
                  @click="handleDeleteSubTopic(tIndex, index)"
                  icon="pi pi-times"
                  class="delete-btn m-0"
                  :title="dictionary.edit_delete_stopic"
                  :disabled="index < 1"
                />
              </div>
            </li>
          </ol>
        </div>
      </div>
      <small v-if="topics.length >= 6" class="p-error">{{
        dictionary.edit_topic_warn
      }}</small>

      <div class="mt-4 flex flex-row w-full items-center">
        <span class="text-left mr-2 custom:text-sm xl:text-base">{{
          dictionary.comparator_tags
        }}</span>
      </div>
      <div class="grid grid-cols-3 gap-4" v-if="selectedModule">
        <FloatLabel
          v-for="(tag, index) in selectedModule.tag"
          :key="index"
          :class="[
            'col-span-1 custom:text-sm xl:text-base custom:text-sm xl:text-base',
          ]"
        >
          <InputText
            :id="'tag_' + index"
            v-model="selectedModule.tag[index]"
            class="uppercase"
          />
          <label :for="'tag_' + index">{{ dictionary.edit_tag }}</label>
        </FloatLabel>
      </div>
      <div v-else class="grid grid-cols-3 gap-4">
        <div v-for="(tag, index) in tags" :key="index">
          <FloatLabel
            :class="[
              'col-span-1 custom:text-sm xl:text-base custom:text-sm xl:text-base',
            ]"
          >
            <InputText
              :id="'tag_' + index"
              v-model="tags[index]"
              class="uppercase"
            />
            <label :for="'tag_' + index">{{ dictionary.edit_tag }}</label>
          </FloatLabel>
          <small v-if="isSubmitted && !tags[index]" class="p-error">{{
            dictionary.edit_error_field
          }}</small>
        </div>
      </div>
      <div class="w-full flex justify-end">
        <Button
          type="submit"
          class="submit-btn"
          :icon="`pi ${!selectedModule ? 'pi-plus' : 'pi-sync'}`"
          :label="
            !selectedModule ? dictionary.login_btn : dictionary.edit_update_btn
          "
        />
      </div>
    </form>
  </Dialog>
  <Toast ref="toast" />
</template>

<script setup>
import { ref, computed, onMounted, watch, toRaw } from "vue";
import { useStore } from "../../../../store/store.js";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import FloatLabel from "primevue/floatlabel";
import Skeleton from "primevue/skeleton";
import Dropdown from "primevue/dropdown";
import Button from "primevue/button";
import Toast from "primevue/toast";
import ProgressSpinner from "primevue/progressspinner";
import { dictionary } from "../../../dictionary.js";

const store = useStore();
const showEdit = computed(() => store.showEditSlotDialog);
const selectedModule = ref(JSON.parse(localStorage.getItem("mod")));
const imgUrl = ref("");
const isValidUrl = ref(true);
const objective = ref("");
const title = ref("");
const shortname = ref("");
const selectedElement = ref("");
const selectedDepartment = ref("");
const selectedDuration = ref("");
const selectedType = ref("");
const selectedTarget = ref("");
const selectedGroup = ref("");
const topic = ref("");
const knowledge = ref("");
const stopic = ref("");
const errmsg = ref("");
const toast = ref(null);
const loading = ref(false);
const isSubmitted = ref(false);
const topics = ref([
  {
    name: "",
    knowledge: "",
    stopic: [{ name: "", skill: "" }],
  },
]);
const tags = ref(["", "", "", "", "", ""]);

const trainingElements = [
  { name: "Course" },
  { name: "Course + Test" },
  { name: "Course + Test + FL" },
];
const departments = [
  { name: "Sales" },
  { name: "Logistics" },
  { name: "HR" },
  { name: "Others" },
];
const durations = [
  { name: 5 },
  { name: 10 },
  { name: 15 },
  { name: 20 },
  { name: 25 },
  { name: 30 },
];
const types = [
  { name: "MA" },
  { name: "FtF" },
  { name: "Nugget" },
  { name: "Other" },
];

const targets = [{ name: "Employee" }, { name: "Manager" }];
const groups = [{ name: "All" }, { name: "New" }, { name: "Promotion" }];
const skills = ["Basic", "Medium", "Advanced"];

const handleCloseEdit = () => {
  localStorage.removeItem("mod");
  store.$patch({ showEditSlotDialog: false });
};

onMounted(() => {
  if (selectedModule.value) {
    imgUrl.value = selectedModule.value.url;
    title.value = selectedModule.value.title;
    objective.value = selectedModule.value.objective;
    shortname.value = selectedModule.value.short;
    selectedDepartment.value = { name: selectedModule.value.department };
    selectedDuration.value = { name: selectedModule.value.duration };
    selectedType.value = { name: selectedModule.value.type };
    selectedElement.value = { name: selectedModule.value.element };
    selectedTarget.value = { name: selectedModule.value.target };
    selectedGroup.value = { name: selectedModule.value.employee };
    topics.value = selectedModule.value.topic;
    tags.value = selectedModule.value.tag;
  }
});

const handleAddNewTopic = () => {
  topics.value.push({
    name: topic.value,
    knowledge: knowledge.value,
    stopic: [],
  });
  topic.value = "";
  knowledge.value = "";
};

const handleAddNewSubTopic = (topicIndex) => {
  topics.value[topicIndex].stopic.push({
    name: stopic.value,
    skill: "",
  });
  stopic.value = "";
};

const handleDeleteTopic = (tIndex) => {
  topics.value.splice(tIndex, 1);
};

const handleDeleteSubTopic = (tIndex, sIndex) => {
  topics.value[tIndex].stopic.splice(sIndex, 1);
};

const validateUrl = (url) => {
  try {
    new URL(url);
    return true;
  } catch (e) {
    return false;
  }
};

const setToast = () => {
  toast.value.add({
    severity: "success",
    summary: "Success",
    detail: dictionary.edit_toast_info + " " + shortname.value.toUpperCase(),
    life: 2000,
  });
};

const resetForm = () => {
  title.value = "";
  shortname.value = "";
  selectedDuration.value = "";
  selectedType.value = "";
  selectedElement.value = "";
  selectedDepartment.value = "";
  selectedTarget.value = "";
  selectedGroup.value = "";
  objective.value = "";
  topics.value = [
    {
      name: "",
      knowledge: "",
      stopic: [{ name: "", skill: "" }],
    },
  ];
  tags.value = ["", "", "", "", "", ""];
  imgUrl.value = "";
  isSubmitted.value = false;
};

const validateForm = () => {
  isSubmitted.value = true;
  if (
    !title.value ||
    !shortname.value ||
    !selectedDuration.value ||
    !selectedType.value ||
    !selectedElement.value ||
    !selectedDepartment.value ||
    !selectedTarget.value ||
    !selectedGroup.value ||
    !objective.value ||
    !imgUrl.value
  ) {
    toast.value.add({
      severity: "error",
      summary: "Warning!",
      detail: "All fields must be filled out",
      life: 3000,
    });
    return false;
  }
  return true;
};
const onSubmit = async (event) => {
  event.preventDefault();
  if (validateForm()) {
    const log = {
      title: title.value,
      short: shortname.value.toUpperCase(),
      duration: selectedDuration.value.name,
      type: selectedType.value.name,
      element: selectedElement.value.name,
      department: selectedDepartment.value.name,
      target: selectedTarget.value.name,
      employee: selectedGroup.value.name,
      objective: objective.value,
      topic: toRaw(topics.value),
      tag: tags.value.map((tag) => tag.toUpperCase()),
      url: imgUrl.value,
    };
    loading.value = true;
    try {
      const response = await fetch("https://eld-mapper-backend.api.prod.sit.sys.odj.cloud/mod", {
        method: "POST",
        mode: "cors",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(log),
      });

      if (response.ok) {
        setToast();
        errmsg.value = "";
        const timer = setTimeout(() => {
          loading.value = false;
          resetForm();
          store.fetchData();
        }, 1000);
        return () => clearTimeout(timer);
      } else {
        const message = await response.text();
        errmsg.value = message;
        console.error(message);
        loading.value = false;
      }
    } catch (error) {
      console.error(error);
    }
  }
};

const onUpdate = async (event) => {
  event.preventDefault();
  if (validateForm()) {
    const logId = selectedModule.value._id;
    const log = {
      title: title.value,
      short: shortname.value.toUpperCase(),
      duration: selectedDuration.value.name,
      type: selectedType.value.name,
      element: selectedElement.value.name,
      department: selectedDepartment.value.name,
      target: selectedTarget.value.name,
      employee: selectedGroup.value.name,
      objective: objective.value,
      topic: toRaw(topics.value),
      tag: tags.value.map((tag) => tag.toUpperCase()),
      url: imgUrl.value,
    };
    loading.value = true;
    try {
      const response = await fetch(`https://eld-mapper-backend.api.prod.sit.sys.odj.cloud/mod/${logId}`, {
        method: "PATCH",
        mode: "cors",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(log),
      });

      if (response.ok) {
        toast.value.add({
          severity: "success",
          summary: "Success",
          detail:
            dictionary.edit_toast_update + " " + shortname.value.toUpperCase(),
          life: 2000,
        });
        errmsg.value = "";
        const timer = setTimeout(() => {
          loading.value = false;
          handleCloseEdit();
          store.fetchData();
        }, 1000);
        return () => clearTimeout(timer);
      } else {
        const message = await response.text();
        errmsg.value = message;
        console.error(message);
        loading.value = false;
      }
    } catch (error) {
      console.error(error);
    }
  }
};

watch(
  () => imgUrl.value,
  (newUrl) => {
    isValidUrl.value = validateUrl(newUrl);
  }
);
</script>

<style scoped>
.img-placeholder {
  width: 180px;
  height: 100px;
}

form {
  color: #c4e9ff;
}
.uppercase {
  text-transform: uppercase;
}
.spinner-wrapper {
  background-color: rgba(17, 24, 39, 0.5);
}
.spinner {
  width: 50px;
  height: 50px;
}

.delete-btn {
  background-color: #ef4444;
  padding: 0;
  width: 25px;
  height: 25px;
}
</style>